import { gql } from '@apollo/client';

export const getSlider = gql`
    query getSlider($input: InputSlider) {
        slider(input: $input) {
            slider_id
            images {
                image_id
                image_url
                thumb_image_url
                mobile_image_url
                url_redirection
                video
            }
        }
    }
`;

export const getBannerSlider = gql`
    {
        getHomepageSlider {
            slider_id
            images {
                image_id
                image_url
                mobile_image_url
                thumb_image_url 
                url_redirection
            }
        }
    }
`;

export const getFeaturedProducts = (config = {}) => gql`
    query($url_key: String!) {
        categoryList(filters: { url_key: { eq: $url_key } }) {
            children {
                id
                name
                path
                image_path
                url_path
                products {
                    items {
                        __typename
                        id
                        name
                        sku
                        url_key
                        new_from_date
                        new_to_date
                        canonical_url
                        small_image {
                            url,
                        }
                        ${config?.pwa?.label_weltpixel_enable ? `
                            weltpixel_labels {
                            categoryLabel {
                                css
                                customer_group
                                image
                                page_position
                                position
                                priority
                                text
                                text_padding
                                text_bg_color
                                text_font_size
                                text_font_color          
                            }
                            productLabel {
                                css
                                customer_group
                                image
                                page_position
                                position
                                priority
                                text
                                text_padding
                                text_bg_color
                                text_font_size
                                text_font_color  
                            }
                        }        
                        ` : ''}
                        price_tiers {
                            discount {
                                amount_off
                                percent_off
                            }
                            final_price {
                                currency
                                value
                            }
                            quantity
                        }
                        price_range {
                            minimum_price {
                                regular_price {
                                    currency
                                    value
                                }
                                final_price {
                                    currency
                                    value
                                }
                            }
                        }
                        special_from_date
                        special_to_date
                    }
                }
            }
            children_count
        }
    }
`;

export const getCategoryList = gql`
    query($url_key: String!) {
        categoryList(filters: { url_key: { eq: $url_key } }) {
            children {
                id
                name
                description
                image_path
                url_path
            }
            children_count
        }
    }
`;

export const getCmsPageConfig = gql`
    {
        storeConfig {
            pwa {
                use_cms_page_enable
                use_cms_page_identifier
            }
        }
    }
`;

export const getHomePageConfig = `
{
    storeConfig {
        pwa {
            use_cms_page_enable
            use_cms_page_identifier
            banner_slider_enable
            banner_slider_title
            category_list_enable
            category_list_url_key            
            category_list_image_size_desktop_height
            category_list_image_size_desktop_width
            category_list_image_size_mobile_height
            category_list_image_size_mobile_width
            features_product_enable
            features_product_url_key
        }
    }
}
`;

export const categoryPromoSchema = (config = {}) => gql`
    {
        categories(
            filters: {
            name: { match: "Promo" }
            }
        ) {
            total_count
            items {
                uid
                level
                name
                path
                url_key
                children_count
                children {
                    uid
                    url_key
                    level
                    name
                    path
                    url_path
                    children_count
                    products(
                        pageSize: 10
                        currentPage: 1
                    ) {
                        items {
                            id
                            uid
                            sku
                            name
                            url_key
                            cek_notify
                            # brand
                            small_image{
                                url
                                label
                            } 
                            image {
                                url
                            }
                            vendor_product_data(
                                selectedStore: ${config.vendorId}
                            ) {
                                currency_code
                                currency_symbol
                                pareto_price
                                final_pareto_price
                                pareto_price_min_qty
                                price
                                final_price
                                sku
                                special_price
                                final_special_price
                                special_price_from
                                special_price_to
                                stock
                                tier_price {
                                    customer_group
                                    price
                                    final_price
                                    quantity
                                }
                                vendor_id
                            }
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                    discount{
                                        amount_off
                                        percent_off
                                    }
                                }
                                maximum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                    discount{
                                        amount_off
                                        percent_off
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const categoryLimitedOfferSchema = (config = {}) => gql`
    {
        categories(
            filters: {
                name: { match: "Produk Penawaran Terbatas" }
            }
        ) {
            total_count
            items {
                uid
                level
                name
                path
                url_key
                children_count
                products(
                    pageSize: 15
                    currentPage: 1
                ) {
                    items {
                        id
                        uid
                        sku
                        name
                        cek_notify
                        # brand
                        url_key
                        small_image{
                            url
                            label
                        } 
                        image {
                            url
                        }
                        vendor_product_data(
                            selectedStore: ${config.vendorId}
                        ) {
                            currency_code
                            currency_symbol
                            pareto_price
                            final_pareto_price
                            pareto_price_min_qty
                            price
                            final_price
                            sku
                            special_price
                            final_special_price
                            special_price_from
                            special_price_to
                            stock
                            tier_price {
                                customer_group
                                price
                                final_price
                                quantity
                            }
                            vendor_id
                        }
                        price_range {
                            minimum_price {
                                regular_price {
                                    value
                                    currency
                                }
                                final_price {
                                    value
                                    currency
                                }
                                discount{
                                    amount_off
                                    percent_off
                                }
                            }
                            maximum_price {
                                regular_price {
                                    value
                                    currency
                                }
                                final_price {
                                    value
                                    currency
                                }
                                discount{
                                    amount_off
                                    percent_off
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const categoryHomepageSchema = gql`
    {
        categories(
            filters: {
                is_home_pages: { in: ["1"] }
            }
        ) {
            total_count
            items {
                is_home_pages
                image
                uid
                name
                path
                url_key
            }
        }
  }
`;

export const homeBrandListSchema = gql`
    query {
        getBrandList (
            pageSize:10
            currentPage: 1
        ) {
            page_size
            featured {
                attribute_id
                logo
                name
                sort_order
                is_active
                category_url
            }
        }
    }
`;

export default {
    getBannerSlider,
    getCategoryList,
    getFeaturedProducts,
    getSlider,
    getCmsPageConfig,
    categoryPromoSchema,
    categoryLimitedOfferSchema,
    categoryHomepageSchema,
    homeBrandListSchema,
};
